<template>
    <b-modal id="modal-password" ref="modal" centered :title="$t('pwd.title')" title-class="font-18" hide-footer>

                                    <b-card-text>
                                        <form id="form"  method="POST" @submit.prevent="handleSubmit">
                                            <div class="form-group  mb-4">
                                                <label>{{$t('pwd.old_pwd')}}</label>
                                                <a href="#" class="float-right">{{$t('pwd.forgot')}}</a>
                                                <b-form-input type="password" v-model="pwd.old_password"
                                                              name="old_password"
                                                              class="form-control"
                                                              :class="{ 'is-invalid': typesubmit && $v.pwd.old_password.$error }"
                                                              :placeholder="$t('pwd.place_holder')"></b-form-input>
                                                <div v-if="typesubmit && $v.pwd.old_password.$error" class="invalid-feedback">
                                                    <span v-if="!$v.pwd.old_password.required"> {{$t('pwd.required')}}</span>
                                                </div>
                                            </div>

                                            <div class="form-group  mb-4">
                                                <label>New Password :</label>
                                                <b-form-input type="password" v-model="pwd.new_password"
                                                              name="new_password"
                                                              class="form-control"
                                                              :class="{ 'is-invalid': typesubmit && $v.pwd.new_password.$error }"
                                                              :placeholder="$t('pwd.place_holder2')"
                                                             ></b-form-input>
                                                <div v-if="typesubmit && $v.pwd.new_password.$error" class="invalid-feedback">
                                                    <span v-if="!$v.pwd.new_password.required">{{$t('pwd.required')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group  mb-4">
                                                <label> Verify Password :</label>
                                                <b-form-input type="password" v-model="pwd.confirmPassword"
                                                              name="confirmPassword"
                                                              class="form-control"
                                                              :class="{ 'is-invalid': typesubmit && $v.pwd.confirmPassword.$error }"
                                                              :placeholder="$t('pwd.place_holder3')"></b-form-input>
                                                <div v-if="typesubmit && $v.pwd.confirmPassword.$error"
                                                        class="invalid-feedback">
                                                    <span v-if="!$v.pwd.confirmPassword.required">{{$t('pwd.required')}}</span>
                                                    <span
                                                            v-else-if="!$v.pwd.confirmPassword.sameAsPassword"
                                                    >{{$t('pwd.same')}}</span>
                                                </div>
                                            </div>

                                            <div class="form-group mt-3 mb-0">
                                            <input type="submit" class="btn float-right btn-primary" :value="$t('pwd.save')"/>
                                            </div>
                                        </form>
                                    </b-card-text>




    </b-modal>
</template>

<script>
    import { required,sameAs } from "vuelidate/lib/validators";
    import helper from "@/assets/helpers/helper";
    export default {
        name: "changePassword",
        data(){
            return{
                typesubmit: false,
                pwd: {
                    old_password: "",
                    new_password: "",
                    confirmPassword: "",
                },
            }
        },
        validations: {
            pwd:{
                old_password: {required},
                new_password: {required},
                confirmPassword: {required, sameAsPassword: sameAs("new_password")}
            },
        },
        methods: {
            create(){
                this.typesubmit= false
                    this.pwd= {
                    old_password: "",
                        new_password: "",
                        confirmPassword: "",
                }

            },
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.changePwd();

            },
            async changePwd(){
                let re= await helper.postReturnData('profil/change_password',this.pwd)
                console.log(re);
                if(re)   this.$refs['modal'].hide()
            }
        }
    }
</script>

<style scoped>

</style>