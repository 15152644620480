<template>
    <form id="form"  method="POST" @submit.prevent="handleSubmit">
        <div class="form-group mt-3 mb-0">
            <label>{{$t('profil_team.name')}} :</label>

            <b-form-input type="text" v-model="team.name" :placeholder="$t('profil_team.name_ph')"
                          :class="{ 'is-invalid': typesubmit && $v.team.name.$error }"></b-form-input>
            <div v-if="typesubmit && $v.team.name.$error" class="invalid-feedback">
                <span v-if="!$v.team.name.required"> {{$t('validation.required')}}</span>
                <span v-if="!$v.team.name.alphaNum"> {{$t('validation.invalid')}}</span>
                <span v-if="!$v.team.name.minLength || !$v.team.name.maxLength">3-15 caracters</span>

            </div>
        </div>
        <div class="form-group mt-3 mb-0">
            <label>{{$t('profil_team.country')}} :</label>

            <country-select id="country" v-model="team.country"  :blackList="['EH']" class="custom-select" :countryName="true"
                            :class="{ 'is-invalid': typesubmit && $v.team.country.$error }" :autocomplete="true"/>
            <div v-if="typesubmit && $v.team.country.$error" class="invalid-feedback">
                <span v-if="!$v.team.country.required"> {{$t('validation.required')}}</span>
            </div>
        </div>


        <input class="btn btn-primary float-right mt-3" :value="$t('profil_team.submit')" type="submit" />
    </form>
</template>

<script>
    import {alphaNum, maxLength, minLength, required} from "vuelidate/lib/validators";
    import helper from '@/assets/helpers/helper';

    export default {
        name: "createTeam",
        data(){
            return{
                typesubmit: false,
                team: {
                    name: '',
                    country: '',
                }
            }
        },
        validations: {
            team:{
                name: {required,alphaNum,minLength: minLength(3),maxLength: maxLength(15)},
                country: {required},
            },
        },
        methods: {
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.addTeam();

            },
            async addTeam() {
                let a = await helper.addItem('teams',this.team)
                if(a){
                    this.$emit('refresh')
                    this.$router.push({name:'ProfilTeam',params:{id:a.id} })

                }
            },
        },
    }
</script>

<style scoped>

</style>