<template>
    <b-modal id="add-university" centered :title="$t('profil_user.settings.add_uni')" ref="modal"  title-class="font-18"
             hide-footer @shown="create">

            <div class="row">

                <div class="col-xl-11 m-auto font-size-14">
                    <form id="form"  method="POST" @submit.prevent="handleSubmit">
                    <div class="form-group mt-3 mb-0">
                        <label>{{$t('clb.uni_name')}} :</label>
                        <b-form-input type="text" v-model="item.name"  :class="{ 'is-invalid': typesubmit && $v.item.name.$error }"></b-form-input>
                        <div v-if="typesubmit && $v.item.name.$error" class="invalid-feedback">
                            <span v-if="!$v.item.name.required"> {{$t('validation.required')}}</span>
                        </div>
                    </div>
                        <div class="form-group mt-3 mb-0">
                            <label>{{$t('profil_team.country')}} :</label>

                            <country-select id="country" v-model="item.country" :blackList="['EH']" class="custom-select" :countryName="true"
                                            :class="{ 'is-invalid': typesubmit && $v.item.country.$error }"/>
                            <div v-if="typesubmit && $v.item.country.$error" class="invalid-feedback">
                                <span v-if="!$v.item.country.required"> {{$t('validation.required')}}</span>
                            </div>

                        </div>
                        <div class="form-group mt-3 mb-0">
                            <label>{{$t('profil_user.settings.website')}} :</label>
                            <b-form-input type="text" v-model="item.website_url" ></b-form-input>
                        </div>

                        <div class="form-group mt-3 mb-0">
                        <label>{{$t('clb.uni_other')}} :</label>
                        <b-form-textarea rows="6" v-model="item.description"></b-form-textarea>
                    </div>
                    <input type="submit" class="btn btn-primary float-right mt-3" variant="primary" :value="$t('competition.submit')"/>
                    </form>
                </div>


            </div>

    </b-modal>
</template>

<script>
    import helper from "@/assets/helpers/helper";
    import {required} from "vuelidate/lib/validators";
    export default {
        name: "addUniversity",
        data(){
            return{
                typesubmit:false,
                item:{
                }

            }
        },
        validations: {
            item:{
                name: {required},
                country: {required},
            },
        },
        methods: {
            create(){
                this.typesubmit = false,
                this.item = {}
            },
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.addUni();

            },
            async addUni(){
                let re= await helper.postReturnData('universities/request',this.item)
                if(re)   this.$refs['modal'].hide()

            }
        }
    }
</script>

<style scoped>

</style>