<script>


  import appConfig from "@/app.config.json";
  import PostInput from "@/components/postInput";
  import Post from "@/components/post";
  import Favorite from "@/components/popups/favorite";
  import ChangePassword from "@/components/popups/changePassword";
  import helper from "@/assets/helpers/helper";
  import MyProfileHeader from "@/components/myProfileHeader";
  import Multiselect from "vue-multiselect";
  import AddUniversity from "@/components/popups/addUniversity";
  import Evolution from "../../components/evolution";
  import ChallSolvedByCat from "../../components/challSolvedByCat";
  import Edit from "../../components/popups/edit";

  export default {
    page: {
      title: "Profile",
      meta: [{name: "description", content: appConfig.description}]
    },
    name: 'MyProfil',
    components: {
      Edit,
      ChallSolvedByCat,
      Evolution,
      AddUniversity,
      MyProfileHeader,Multiselect, ChangePassword, Favorite, Post, PostInput},
    data() {
      return {
        title: "Profile",
        posts: [],
        auth:{
          email:'',
          password:'',
        },
        id:'',
        following:[],
        followers:[],
        profil_info: {

        },
        user: {
          profile:{}
        },
        page_posts:1,
        educations:['High School','Bachelor\'s Degree','National Master\'s Degree','Doctoral Studie - Phd','Others'],
        is_pwd:false,
        universities:[],
        streaming:{},
        content:'',
        post_id:'',
        s_index:'',
        company_id:'',
        comment_id:'',
        type:'',
        followings_count:0,
        followers_count:0,
        loadFollow:false


      };
    },
    created(){
      this.loadInfo()
      this.getFollow()
      this.loadUniversities()
      this.loadMyStream()
    },

    methods: {

      async loadUniversities() {
        this.universities = await helper.getAll('universities');
      },
      async loadMyStream() {
        this.streaming = await helper.getAll('me/streaming');
        if(this.streaming.length == 0) this.streaming = {}
      },
      async loadInfo(){
       let p  =  await helper.getAll('me/user');
        this.user = p;
        this.auth.email = p.email
        this.profil_info = p.profile;
        document.title = this.profil_info.username + ' | CRISIS'

      },
        deleteThisPost(index){
            console.log(index);
            this.posts.splice(index,1);
        },
      editPost(post,post_id,company_id,comment_id,type){
        this.content = post
        this.post_id = post_id
        this.company_id = company_id
        this.comment_id = comment_id
        this.type = type
        this.$root.$emit('bv::show::modal', 'modal-edit')
      },
      refreshPost(content){
        if(this.type == 'posts' || this.type == 'companies') this.posts[this.s_index].content = content
        if(this.type == 'comments' || this.type == 'cc') {
          this.posts[this.s_index].comments.forEach(element => {
            if(element.id == this.comment_id) element.content = content
          });
        }
      },

      async removeFollow(id,index) {

             let a= await helper.postReturnData('users/'+id+'/follow');

             if(a) {
               this.following.splice(index,1);
               this.followings_count--
             }

        },
      async getFollow() {
        let f1 = await helper.getAll('me/following');
          this.following = f1.follow.data
        this.followings_count = f1.followings_count
        let f2 = await helper.getAll('me/followers');
          this.followers = f2.follow.data
        this.followers_count = f2.followers_count
        this.loadFollow = true
      },


      async editProfil(){

            if(this.profil_info.user_university) this.profil_info.university = this.profil_info.user_university.id
            await helper.postReturnData('profil/edit',this.profil_info)

       },
      async editStreaming(){
        if(this.streaming.id) await helper.postReturnData('me/streaming',this.streaming);
        else  await helper.postReturnData('streamings',this.streaming);
      },
      async editAuth(){
        if(this.is_pwd) {
          let r = await helper.postReturnData('profil/auth/edit',this.auth)
          if(r) this.is_pwd = false
        }
        else this.is_pwd = true
      },
      async editSocial(){
        let social = {
          "username_linkedin":this.profil_info.username_linkedin,
          "username_github":this.profil_info.username_github,
          "username_hackerone":this.profil_info.username_hackerone
        }

         await helper.postReturnData('profil/social/edit',social)

      },
      refresh(post){
        post.new = true
        this.posts.unshift(post)
      },
      infiniteHandler($state) {
        this.$http.get('me/posts?page=' +this.page_posts).then(({ data }) => {
          if (data.length) {
            this.page_posts += 1;
            this.posts.push(...data);
            $state.loaded();
          } else {
            $state.complete();
          }
        });
      },
    }

  };
</script>

<template>
  <div>

    <div class="row" >
      <div class="col-lg-12">
       <my-profile-header  :user="user.profile"/>
      </div>
    </div>
    <div class="row" >
      <div class="col-xl-3 d-none d-md-block">

          <evolution />

      </div>

      <div class="col-xl-5 col-sm-12">

        <b-tabs pills justified content-class="pt-3 text-muted">
          <b-tab  class="border-0">
            <template v-slot:title>
              <span class=" d-sm-inline-block ">{{$t('profil_user.posts')}}</span>
            </template>
            <div>
              <post-input  :logo="$store.state.user.avatar" v-on:refresh="refresh" ></post-input>

              <!-- Posts  -->
              <div v-for="(post,index) in posts" :key="post.id">
                <post :post="post" v-on:delete-post="deleteThisPost(index)" v-on:show-edit="editPost" v-on:show="s_index = index"></post>
              </div>
              <edit :content="content" :post_id="post_id" :type="type" :company_id="company_id" :comment_id="comment_id" v-on:refresh="refreshPost"/>
              <infinite-loading @infinite="infiniteHandler">
                <div slot="no-more">{{$t('validation.no_more_posts')}}</div>
                <div slot="no-results">{{$t('validation.no_posts')}}</div>
              </infinite-loading>

            </div>
          </b-tab>
          <b-tab class="card card-body">
            <template v-slot:title>
              <span class=" d-sm-inline-block">{{$t('profil_user.follow')}}</span>
            </template>
            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class=" d-sm-inline-block">{{$t('profil_user.following')}}</span>
                  <span class="badge badge-pill badge-soft-primary font-size-14  float-right">{{followings_count}}</span>
                </template>
                <div class="row mt-4">
                  <div v-for="(f,index) in following" :key="f.id" class="col-xl-6 col-sm-6 mb-2">
                    <div class="media">
                      <div class="avatar-md mr-4">

                       <img :src="f.user_following.avatar" class="avatar-title rounded-circle" alt height="30"/>

                      </div>

                      <div class="media-body overflow-hidden mt-3">
                        <i class="text-danger link font-size-22 float-right bx bx-user-x" @click="removeFollow(f.user_id,index)"></i>
                        <router-link class="h6 text-truncate  font-size-15 link" :to="{name:'ProfilUser',params:{id:f.user_id}}">{{f.user_following.username}}</router-link>
                        <p class="text-muted mb-4">{{new Date(f.created_at).toLocaleString()}}</p>

                      </div>
                    </div>
                  </div>


                </div>
                <div class="row" v-if="following.length == 0 && loadFollow">
                  <div class="col-12">
                    <span class="text-muted text-center"> {{$t('validation.no_following')}}</span>

                  </div>
                </div>
                <div class="row" v-if="following.length >=8">
                  <div class="col-12">
                    <div class="text-right "><a href="javascript:void(0);"
                                                    class="text-success">
                      {{$t('post.loadmore')}} </a></div>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class=" d-sm-inline-block">{{$t('profil_user.followers')}}</span>
                  <span class="badge badge-pill badge-soft-primary font-size-14  float-right">{{followers_count}}</span>
                </template>
                <div class="row mt-4">
                  <div v-for="f in followers" :key="f.id" class="col-xl-6 col-sm-6 mb-2">
                    <div class="media">
                      <div class="avatar-md mr-4">

                        <img :src="f.user_follower.avatar" class="avatar-title rounded-circle" alt height="30"/>

                      </div>

                      <div class="media-body overflow-hidden">
                        <router-link class="h6 text-truncate mt-3 font-size-15 link" :to="{name:'ProfilUser',params:{id:f.followed_by}}">{{f.user_follower.username}}
                        </router-link>
                        <p class="text-muted mb-4">{{new Date(f.created_at).toLocaleString()}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="followers.length == 0 && loadFollow">
                  <div class="col-12">
                    <span class="text-muted text-center"> {{$t('validation.no_followers')}}</span>
                  </div>
                </div>
                <div class="row"  v-if="followers.length >=8">
                  <div class="col-12">
                    <div class="text-right"><a href="javascript:void(0);"
                                                    class="text-success">
                      {{$t('post.loadmore')}} </a></div>
                  </div>
                </div>
              </b-tab>

            </b-tabs>
          </b-tab>
          <b-tab  class="card card-body" :active="$route.query && $route.query.s == '1'">
            <template v-slot:title>
              <span class=" d-sm-inline-block">{{$t('profil_user.settings.text')}}</span>
            </template>
            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
              <b-tab>
                <template v-slot:title>
                  <span class="text-muted d-sm-inline-block">{{$t('profil_user.settings.personal')}}</span>

                </template>
                <form ref="form">
                  <div class="row pt-3">
                    <div class="col-xl-6">
                      <b-form-group :label="$t('profil_user.settings.full_name')" >
                        <b-form-input type="text" v-model="profil_info.full_name"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-xl-6">
                      <b-form-group :label="$t('profil_user.username')" >
                        <b-form-input type="text" v-model="profil_info.username"></b-form-input>
                      </b-form-group>

                    </div>

                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                    <b-form-group :label="$t('profil_user.country')">

                      <country-select id="country"   v-model="profil_info.country" :country="profil_info.country" :blackList="['EH']" class="custom-select" :countryName="true"/>
                    </b-form-group>
                    </div>
                    <div class="col-xl-6">
                      <b-form-group :label="$t('profil_user.settings.tele')" >
                        <b-form-input type="text"  v-model="profil_info.phone"></b-form-input>
                      </b-form-group>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <b-form-group :label="$t('profil_user.settings.gender.text')" >
                        <b-form-radio-group class="pt-2"   v-model="profil_info.gender"
                                            :options="[$t('profil_user.settings.gender.male'), $t('profil_user.settings.gender.female')]"></b-form-radio-group>
                      </b-form-group>
                    </div>

                    <div class="col-xl-6">
                      <b-form-group :label="$t('profil_user.settings.birth')">
                        <b-form-datepicker  v-model="profil_info.birthday" required></b-form-datepicker>
                      </b-form-group>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                  <b-form-group class="mt-2" :label="$t('profil_user.settings.education')">
                    <b-form-select v-model="profil_info.education"
                                   :options="educations"></b-form-select>
                  </b-form-group>
                    </div>
                    <div class="col-xl-6  my-auto pt-2 text-center">
                      <a class="btn btn-outline-warning"  v-b-modal.modal-favorite>{{$t('profil_user.settings.change_fvt')}}</a>
                      <favorite/>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-xl-6">
                      <div class="form-group">
                        <label class="my-2">{{$t('profil_user.settings.university')}}</label>
                        <multiselect v-model="profil_info.university"
                                     :options="universities" track-by="id" v-bind:value="id"
                                     label="name" open-direction="bottom" :searchable="true"
                                     noResult='ss' tag-placeholder="add"
                                     :show-labels="false"
                                     :placeholder="$t('profil_user.settings.add_uni_ph')">
                        </multiselect>

                      </div>
                    </div>
                      <div class="col-xl-6 my-auto pt-2 text-center">
                        <a class="btn btn-primary " role="button" v-b-modal.add-university>{{$t('profil_user.settings.add_uni')}}</a>
                        <add-university/>
                      </div>

                  </div>

                  <div class="row">
                    <div class="col-xl-2">
                      <button class="btn btn-success mb-2" type="button" @click="editProfil">{{$t('profil_user.save')}}</button>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="text-muted d-sm-inline-block">{{$t('profil_user.settings.auth')}}</span>

                </template>
                <form ref="form">

                  <div class="row">

                    <div class="col-xl-12">
                        <b-form-group :label="$t('profil_user.settings.email')" >
                          <b-form-input type="email"  v-model="auth.email"></b-form-input>
                        </b-form-group>
                      <b-form-group :label="$t('profil_user.settings.password')" v-if="is_pwd">
                        <b-form-input   type="password" v-model="auth.password" :placeholder="$t('profil_user.settings.password_ph')"></b-form-input>
                        <span class="p-2 text-danger">{{$t('profil_user.settings.password_p')}}</span>
                      </b-form-group>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-xl-4">
                      <button class="btn btn-success mb-2" type="button" @click="editAuth">{{$t('profil_user.save')}}</button>
                    </div>

                      <a class="btn btn-outline-danger col-xl-5 ml-auto" style="padding-top: 12px" v-b-modal.modal-password>{{$t('profil_user.settings.change_pwd')}}</a>
                      <change-password/>

                  </div>




                </form>

              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="text-muted d-sm-inline-block">{{$t('profil_user.settings.social.title')}}</span>

                </template>
                <form ref="form">

                  <div class="row">

                    <div class="col-xl-12">
                      <b-input-group class="mt-3">
                        <template #prepend>
                          <b-input-group-text>
                            <i class="fab fa-hackerrank mr-2"></i>
                           <span class="pr-4">https://hackerone.com/</span>
                          </b-input-group-text>
                        </template>
                        <b-form-input type="text" :placeholder="$t('profil_user.settings.social.hacker_ph')"  v-model="profil_info.username_hackerone"></b-form-input>
                      </b-input-group>
                      <b-input-group class="mt-3">
                      <template #prepend>
                        <b-input-group-text class="pr-5">
                          <i class="fab fa-github mr-2" ></i>
                          <span class="pr-4">https://github.com/</span>
                        </b-input-group-text>
                      </template>
                      <b-form-input type="text" :placeholder="$t('profil_user.settings.social.github_ph')"  v-model="profil_info.username_github"></b-form-input>
                    </b-input-group>
                      <b-input-group class="mt-3">
                        <template #prepend>
                          <b-input-group-text>
                            <i class="fab fa-linkedin mr-2"></i>
                            <span>https://www.linkedin.com/in/</span>
                          </b-input-group-text>
                        </template>
                        <b-form-input type="text" :placeholder="$t('profil_user.settings.social.linkdin_ph')"  v-model="profil_info.username_linkedin"></b-form-input>
                      </b-input-group>


                    </div>

                  </div>
                  <div class="row m-3">
                      <button class="btn btn-success" type="button" @click="editSocial">{{$t('profil_user.save')}}</button>
                  </div>
                </form>

              </b-tab>
              <b-tab :active="$route.query && $route.query.streaming == '1'">
                <template v-slot:title>
                  <span class="text-muted  d-sm-inline-block">{{$t('profil_user.settings.streaming')}}</span>
                </template>
                  <div role="tablist">
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" role="tab" v-b-toggle.about style="cursor: pointer">
                        <h6 class="m-0 text-dark">
                          {{$t('streamings.how_streamer.title')}}
                        </h6>
                      </b-card-header>
                      <b-collapse id="about" class="mx-1" accordion="my-accordion" role="tabpanel">

                            <h6 class="my-3"> {{$t('streamings.how_streamer.h')}}</h6>
                            <ul class="m-2 ">
                              <li> {{$t('streamings.how_streamer.l1')}}</li>
                              <li> {{$t('streamings.how_streamer.l2')}}</li>
                              <li> {{$t('streamings.how_streamer.l3')}}</li>
                              <li> {{$t('streamings.how_streamer.l4')}}</li>
                            </ul>

                      </b-collapse>
                    </b-card>
                      <b-card no-body class="mb-1">
                          <b-card-header header-tag="header" role="tab" v-b-toggle.rules style="cursor: pointer">
                              <h6 class="m-0 text-dark">
                                  {{$t('streamings.rules.title')}}
                              </h6>
                          </b-card-header>
                          <b-collapse id="rules" accordion="my-accordion" role="tabpanel">

                                    <ul class="m-2">
                                      <li> {{$t('streamings.rules.l1')}}</li>
                                      <li> {{$t('streamings.rules.l2')}}</li>
                                      <li> {{$t('streamings.rules.l3')}}</li>
                                      <li> {{$t('streamings.rules.l4')}}</li>
                                      <li> {{$t('streamings.rules.l5')}}</li>
                                      <li> {{$t('streamings.rules.l6')}}</li>
                                      <li> {{$t('streamings.rules.l7')}}</li>
                                      <li> {{$t('streamings.rules.l8')}}</li>
                                      <li> {{$t('streamings.rules.l9')}}</li>
                                    </ul>

                          </b-collapse>
                      </b-card>

                      <b-card no-body class="mb-1 shadow-none">
                          <b-card-header header-tag="header" role="tab" v-b-toggle.info style="cursor: pointer">
                              <h6 class="m-0 text-dark">
                                  {{$t('profil_user.settings.info')}}
                              </h6>
                          </b-card-header>
                          <b-collapse id="info" class="mx-1" visible accordion="my-accordion" role="tabpanel">

                                <div class="row ">
                                  <span class="badge badge-pill ml-auto font-size-12 badge-soft-primary p-2 m-2" v-if="streaming.state == 'approved'">{{$t('profil_user.settings.approved')}}</span>
                                  <span class="badge badge-pill ml-auto font-size-12 badge-soft-warning p-2 m-2" v-if="streaming.state == 'pending'">{{$t('profil_user.settings.pending')}}</span>
                                  <span class="badge badge-pill ml-auto font-size-12 badge-soft-danger p-2 m-2" v-if="streaming.state == 'not_approved'">{{$t('profil_user.settings.not_approved')}}</span>
                                </div>
                                        <form ref="form" class="pb-4 ">
                  <b-form-group class="mt-3" :label="$t('profil_user.settings.twitch_user')" >
                    <b-form-input type="text" v-model="streaming.username_twitch" :placeholder="$t('profil_user.settings.twitch_user_ph')"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-3" :label="$t('profil_user.settings.streamer')" >
                    <b-form-input type="text" v-model="streaming.name" :placeholder="$t('profil_user.settings.streamer_ph')"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-3" :label="$t('profil_user.settings.title')" >
                    <b-form-input type="text" v-model="streaming.title" :placeholder="$t('profil_user.settings.title_ph')"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-3" :label="$t('profil_user.settings.bio')" >
                    <b-form-textarea rows="4" v-model="streaming.bio" :placeholder="$t('profil_user.settings.bio_ph')"></b-form-textarea>
                  </b-form-group>
                  <button class="btn btn-success mb-4 float-right" type="button" @click="editStreaming">{{$t('profil_user.save')}}</button>
                </form>

                          </b-collapse>
                      </b-card>

                  </div>

              </b-tab>

            </b-tabs>


          </b-tab>
        </b-tabs>
      </div>


      <!-- end col -->
      <div class="col-xl-4 d-none d-md-block ">
       <chall-solved-by-cat />

      </div>
    </div>
    <!-- end row -->
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
  .multiselect__content-wrappe {
    max-height: 140px;
  }
  .multiselect__tags{
    max-height: 100px ;
    overflow-y: scroll;
  }
  li{
    margin-bottom: 12px;
    font-size: 12px;
  }

</style>