<template>
    <b-modal id="modal-favorite" ref="modal" centered :title="$t('fvt.title')" title-class="font-18" hide-footer @shown="create">

                                    <b-card-text>
                                        <form>
                                            <div class="form-group  mb-0">
                                                <multiselect v-model="cat"
                                                             :options="categories"
                                                             track-by="name"
                                                             label="name"
                                                             :multiple="true"
                                                             :searchable="true"
                                                             :clear-on-select="false"
                                                             :close-on-select="false"
                                                             :show-no-results="false"
                                                             :show-labels="false"
                                                             noResult='ss'
                                                             tag-placeholder="add"
                                                             :placeholder="$t('fvt.add')"

                                                >
                                                </multiselect>

                                            </div>
                                            <div class="form-group mt-3 mb-0">
                                            <input type="button" @click="editPreferences"  class="btn float-right btn-primary" :value="$t('pwd.save')"/>
                                            </div>
                                        </form>
                                    </b-card-text>




    </b-modal>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import helper from "@/assets/helpers/helper";

    export default {
        name: "favorite",
        components:{Multiselect},
        data(){
            return{
                cat:[],

                categories: [],
            }
        },

        methods:{
            create(){
                this.getCategories()
                this.getPreferences()
            },


            async getCategories(){

                this.categories= await helper.getAll('categories');
            },
            async getPreferences(){
                let cat = []
                let category = await helper.getAll('me/preferences');
                category.forEach(function (item) {
                    cat.push(item.category)
                });
                this.cat = cat
            },
            async editPreferences(){
                let categories_id = []
                this.cat.forEach(function (item) {
                    categories_id.push(item.id)
                });
                let re = await helper.postData('me/preferences',{'categories_id':categories_id})
                if(re)   this.$refs['modal'].hide()
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
    .multiselect__content-wrappe {
        max-height: 100px;
    }
    .multiselect__tags{
        max-height: 60px ;
        overflow-y: scroll;
    }

</style>