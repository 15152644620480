<template>
  <b-modal id="avatar-choice" centered  ref="modal"  title-class="font-22"
           hide-footer  hide-header  @shown="create" @hide="show=false">
    <div class="card mb-0">
      <div class="card-body  font-size-14">

        <div class="row mx-0">
          <div class="col-4 mx-auto my-4" >
            <img class="image" v-bind:src="selected_image">
          </div>
        <div class="row mx-0  justify-content-center">
          <div class=" col-xl-2 col-4 mb-4" v-for="data in avatars" v-bind:key="data.id">

            <img  class="image" v-bind:src="data.image"  @click="selected_image = data.image">

          </div>
        </div>

        </div>
        <div class="row w-75 mx-auto" >
          <button class=" btn btn-primary  mx-auto"   @click="modifyAvatar">
            {{$t('profil_user.save')}}
          </button>
          <button class=" btn btn-outline-light d-flex mx-auto" @click="show = true">
            {{$t('profil_user.upload')}} <i class="bx bx-camera mx-2 inner text-white font-size-16" ></i>
          </button>
          <upload-avatar class="mx-2 inner" url="me/avatar" v-if="show" v-on:close="show=false;$refs['modal'].hide()" type="me"/>
        </div>
      </div>


    </div>

  </b-modal>
</template>

<script>
import UploadAvatar from "../uploadAvatar";
export default {
  name: "AvatarChoice",
  components: {UploadAvatar},
  data(){
    return{
      avatars:[],
      show:false,
      selected_image:this.$store.state.user.avatar
    }
  },
  created(){
    this.getAvatars()
  },

  methods: {

    hideModal() {
      this.$refs['modal'].hide()
    },
    create(){
      this.selected_image = this.$store.state.user.avatar
    },
    modifyAvatar()
    {

      this.$http
          .post('me/avatar_crisis',{image:this.selected_image})
          .then(response => {
            this.$alertify.success(" success")
            this.$store.state.user.avatar = this.selected_image
            console.log(response.data)
            this.hideModal()
          })
          .catch(error => {
            console.log(error)
            this.$alertify.error("Error");
          })
    },
    getAvatars(){
      this.$http
          .get('avatars')
          .then(response => {
            this.avatars = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },



  }
}

</script>

<style scoped>
/deep/  .modal-body{
  padding: 0rem !important;
}
/deep/ .modal-content{

  border-radius: 20px!important;
}

.card{
  background: transparent;
  border: none;
}

input,select{
  border-radius:8px;
}

.image {
  width: 100%;
  cursor: pointer;
  transition: transform .2s; /* Animation */
}



.save{
  min-height: 100%;
  cursor: pointer;
  border-radius: 12px;
}
.inner {
  position: relative;
  top: 2px;
  cursor: pointer;
}
/deep/ .vue-image-crop-upload .vicp-wrap{
  background-color: #2a3042!important;
}
</style>