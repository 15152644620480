<template>
    <b-modal id="modal-create-team" ref="modal"  :title="$t('profil_team.create.new')" title-class="font-18" hide-footer
            >

            <div class="row">

                <div class="col-xl-9 m-auto font-size-14">
                     <create-team v-on:refresh="$refs['modal'].hide()"/>
                </div>


            </div>

    </b-modal>
</template>

<script>

    import CreateTeam from "../createTeam";
    export default {
        name: "newTeam",
        components: {CreateTeam},
    }
</script>

<style scoped>

</style>