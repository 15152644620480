<template>
    <div class="card">
        <div class="card-body">
            <div class="row align-items-center" v-if="user.username">
                <div class="col-lg-3 col-xl-3 col-md-6  col-sm-12 mb-2">
                    <div class="media">
                        <div class="mr-2">
                            <b-avatar class="outer" :src="$store.state.user.avatar" size="lg"  v-if="user.avatar" ></b-avatar>
                           <b-avatar  v-else variant="primary" size="lg" :text="(user.username).substr(0, 1)" class="align-baseline outer"></b-avatar>
                          <i class="bx bx-camera inner text-white font-size-16" v-b-modal.avatar-choice title="Crisis Avatars"></i>
                          <avatar-choice/>
                        </div>
                        <div class="media-body align-self-center">
                            <div class="text-muted">
                                <h5 class="mb-1" >{{user.username}}</h5>
                                <p class="mb-0 ml-1">{{user.country}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9 col-xl-9 col-md-6 col-sm-12 mb-2 text-lg-center mt-lg-0  align-self-center">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                                    <p class="text-muted  mb-2">{{$t('profil_team.score')}}</p>
                                    <h5 class="mb-0">{{user.score?user.score:0}}</h5>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                                    <p class="text-muted  mb-2" >{{$t('profil_user.team')}}</p>
                                    <router-link class="h5 mb-0 link" v-if="user.team[0] " :to="{name:'ProfilTeam',params:{id:user.team[0].id}}">{{user.team[0].name}}</router-link>
                                    <h5 class="mb-0 link" v-else v-b-modal.modal-create-team> <a class="badge badge-success p-2 font-size-12 m-1 mt-2">{{$t('profil_team.create.text')}}</a></h5>
                                    <new-team/>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                                    <p class="text-muted  mb-2">{{$t('profil_team.solved_chall')}}</p>
                                    <h5 class="mb-0">{{user.challenge_solved_count}}</h5>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50" v-if="user.country" >
                                    <p class="text-muted  mb-2">{{$t('profil_team.country_rank')}}</p>
                                    <h5 class="mb-0">{{user.ranks.length?user.ranks[0].rank_country:'-'}}</h5>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                                    <p class="text-muted  mb-2">{{$t('profil_team.world_rank')}}</p>
                                    <h5 class="mb-0">{{user.ranks.length?user.ranks[0].rank_world:'-'}}</h5>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                                    <p class="text-muted  mb-2">{{$t('profil_user.followers')}}</p>
                                    <h5 class="mb-0">{{user.followers_count}}</h5>
                            </div>

                        </div>
                </div>



            </div>
            <div class="row" v-else>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
<div class="row">
    <b-skeleton type="avatar" class="col-sm-2 mr-2"></b-skeleton>
    <div class="col-sm-9">
        <b-skeleton class="mb-2"></b-skeleton>
        <b-skeleton class="mb-2"></b-skeleton>
    </div>
</div>


                </div>
                <div class="col-xl-1 col-lg-1 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_team.score')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-1 col-lg-1 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_user.team')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_team.solved_chall')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_team.country_rank')}}</p>
                    <b-skeleton></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_team.world_rank')}}</p>
                    <b-skeleton></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_user.followers')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>

            </div>
            <!-- end row -->
        </div>
    </div>
</template>

<script>

    import NewTeam from "@/components/popups/newTeam";
    import AvatarChoice from "./popups/AvatarChoice";
    export default {
        name: "myProfileHeader",
        components: {AvatarChoice, NewTeam},
        props:['user']
    }
</script>

<style scoped>
    .outer {
        margin: auto;
        position: relative;
    }

    .inner {
        position: absolute;
        top: 40px;
        left: 54px;
      cursor: pointer;
    }
    .inner2{
      position: absolute;
      top: 40px;
      left:10px;
      cursor: pointer;
    }

</style>